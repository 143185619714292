






















import { Vue, Component } from "vue-property-decorator";
// import { FCGOPaymentStore } from "@/store/modules";
// import { CreateVoucher, RedirectionData } from "@/store/models/payment";
import { getNestedFormData } from "@/utils/helper.utils";
import axios from "axios";

@Component({
  components: {}
})
export default class PaymentFailurePage extends Vue {
  ebpNo: any = "";
  hash_value: any = "";

  // get paymentVerifiedData() {
  //   return FCGOPaymentStore.PaymentVerificationResponse;
  // }

  async beforeMount() {
    this.ebpNo = this.$route.query.ebpNo;
    this.hash_value = this.$route.query.hashValue;

    const data = {
      payment_status: "Failed",
      ebpNo: this.ebpNo,
      hash_value: this.hash_value
    };
    let paymentVerifyFormData = new FormData();
    let paymentVerifyData = getNestedFormData(paymentVerifyFormData, data);

    // await FCGOPaymentStore.paymentVerify(paymentVerifyData);
    await axios.post(
      `https://desisapi.pathway.com.np/api/transaction/payment/fcgo/paid_voucher/verify/`,
      paymentVerifyData
    );
  }
}
